import React from "react";
import { Link, NavLink } from "react-router-dom";
import Download from "../../../shared/download";
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";
import FilmanizeSelectField from "../../../shared/filmanize-select-field";

const EquipmentList = ({ equipments,
  filmId,
  currencySymbol,
  onDownloadFile,
  progress,
  download,
  clearPdf,
  includeDailyRate,
  onChangeDailyRate,
  selectedCategories,
  setSelectedCategories,
  categoryOptions }) => {

  const round = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  return (
    <div className="blade-content wide">
      <p className="menu-label">Equipment Overview</p>
      <FilmanizeSelectField label="Categories" value={selectedCategories} onChange={setSelectedCategories} options={categoryOptions} isMulti={true} />
      <div className="mb-1">
        <FilmanizeCheckbox label="Include daily rate" checked={includeDailyRate} onChange={onChangeDailyRate} />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Equipment</th>
            {includeDailyRate && <th className="has-text-right">Daily Rate</th>}
          </tr>
        </thead>
        {(equipments || []).map((equipment, idx) => {
          return (
            <>
              <tr key={idx}>
                <td>
                  <Link to={`/films/${filmId}/equipment-category/${equipment.categoryId}/equipment`}>
                    {equipment.category}
                  </Link>
                </td>
                <td>
                  <Link to={`/films/${filmId}/equipment-category/${equipment.categoryId}/equipment/${equipment.id}/edit`}>
                    {equipment.name}
                  </Link>
                </td>
                {includeDailyRate && <td className="has-text-right">
                  <Link to={`/films/${filmId}/equipment-category/${equipment.categoryId}/equipment/${equipment.id}/edit`}>
                    {currencySymbol}{round(equipment.dailyRate)}
                  </Link>
                </td>}
              </tr>
            </>
          );
        })}
        {includeDailyRate &&
          <tfoot>
            <tr>
              <td></td>
              <td className="has-text-right">Total</td>
              <td className="has-text-right">
                {currencySymbol}{round(equipments.reduce((acc, equipment) => acc + equipment.dailyRate, 0))}
              </td>
            </tr>
          </tfoot>
        }
      </table>
      <Download
        progress={progress}
        clearPdf={clearPdf}
        download={download}
        downloadFile={onDownloadFile} />
    </div>
  );
};

export default EquipmentList;
